const judeteRomania = [
   { prescurtare: "TOATE", nume: "Toate Judetele" },
   { prescurtare: "AB", nume: "Alba" },
   { prescurtare: "AR", nume: "Arad" },
   { prescurtare: "AG", nume: "Argeș" },
   { prescurtare: "BC", nume: "Bacău" },
   { prescurtare: "BH", nume: "Bihor" },
   { prescurtare: "BN", nume: "Bistrița-Năsăud" },
   { prescurtare: "BR", nume: "Brăila" },
   { prescurtare: "BT", nume: "Botoșani" },
   { prescurtare: "BV", nume: "Brașov" },
   { prescurtare: "BR", nume: "Brăila" },
   { prescurtare: "BZ", nume: "Buzău" },
   { prescurtare: "CS", nume: "Caraș-Severin" },
   { prescurtare: "CL", nume: "Călărași" },
   { prescurtare: "CJ", nume: "Cluj" },
   { prescurtare: "CT", nume: "Constanța" },
   { prescurtare: "CV", nume: "Covasna" },
   { prescurtare: "DB", nume: "Dâmbovița" },
   { prescurtare: "DJ", nume: "Dolj" },
   { prescurtare: "GL", nume: "Galați" },
   { prescurtare: "GR", nume: "Giurgiu" },
   { prescurtare: "GJ", nume: "Gorj" },
   { prescurtare: "HR", nume: "Harghita" },
   { prescurtare: "HD", nume: "Hunedoara" },
   { prescurtare: "IL", nume: "Ialomița" },
   { prescurtare: "IS", nume: "Iași" },
   { prescurtare: "IF", nume: "Ilfov" },
   { prescurtare: "MM", nume: "Maramureș" },
   { prescurtare: "MH", nume: "Mehedinți" },
   { prescurtare: "MS", nume: "Mureș" },
   { prescurtare: "NT", nume: "Neamț" },
   { prescurtare: "OT", nume: "Olt" },
   { prescurtare: "PH", nume: "Prahova" },
   { prescurtare: "SM", nume: "Satu Mare" },
   { prescurtare: "SJ", nume: "Sălaj" },
   { prescurtare: "SB", nume: "Sibiu" },
   { prescurtare: "SV", nume: "Suceava" },
   { prescurtare: "TR", nume: "Teleorman" },
   { prescurtare: "TM", nume: "Timiș" },
   { prescurtare: "TL", nume: "Tulcea" },
   { prescurtare: "VS", nume: "Vâlcea" },
   { prescurtare: "VL", nume: "Vaslui" },
   { prescurtare: "VN", nume: "Vrancea" }
];

export default judeteRomania;